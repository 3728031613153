@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons/font/bootstrap-icons.css');
@import './variables.css';


html, body, h1, h2, h3, h4, h5, h6 {
    font-family: "EB Garamond", system-ui;
    font-style: normal;
    font-optical-sizing: auto;
    margin: 0;
    padding: 0;
    color: var(--main-dark);
    background-color: transparent;
}

.App {
    text-align: center;
    margin: 0 auto;
    max-width: 1200px;
}

/* App.css */
.container {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}
